import React, { useRef, useEffect, useState } from 'react';
import './WaveHelloStatic.css';
import useFadeOnScroll from './UseFadeOnScroll';
import WaveEmoji from '../assets/waving-no-loop.gif';
import EmailImage from '../assets/wavehello.png';

const WaveHello = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { addElementRef, removeElementRef } = useFadeOnScroll({ threshold: 0.5 });

  useEffect(() => {
    addElementRef(sectionRef);
    return () => removeElementRef(sectionRef);
  }, [addElementRef, removeElementRef]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold value as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <>
    <div className="image-section" ref={sectionRef}>
      <div className="image-container">
        {isVisible && <img src={WaveEmoji} alt="Wave Emoji" className="wave-emoji" />}
        <a href="mailto:wave.hello@royston.io" className="email-image">
            <img src={EmailImage} alt="Email Image" style={{maxWidth:'100%'}}/>
        </a>
      </div>
    </div>
    </>
  );
};

export default WaveHello;