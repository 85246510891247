import React, { useRef, useEffect } from 'react';
import './ImageWithText.css';
import CloudAnimation from './CloudAnimation';
import useFadeOnScroll from './UseFadeOnScroll';

const ImageWithText = () => {
  const divRef = useRef(null);
  let dynamicThreshold = window.innerWidth <= 768 ? 0.1 : 0.5;
  const { addElementRef, removeElementRef } = useFadeOnScroll({ threshold: dynamicThreshold });

  useEffect(() => {
    addElementRef(divRef);
    return () => removeElementRef(divRef);
  }, [addElementRef, removeElementRef]);

  return (
    <div ref={divRef} className="container">
      <div className="image-container">
        <CloudAnimation />
      </div>
      <div className="text-container">
      <div className="bio-header padded">
        Enabling bioinformatics in the cloud</div>
      <p class="padded" style={{fontFamily: 'InstrumentSans'}} >
          royston.io is a consultancy that enables bioinformatics in the cloud.
          Our team has over a decade's worth of experience writing bioinformatics code and 
          building cloud infrastructure to the highest standards.
      </p>
      <div style={{height: '2vh'}} />
      <h2 class="padded">We can help with...</h2>
      <ul class="padded-list" style={{fontFamily: 'InstrumentSans'}} >
        <li>Fractional CTO Roles</li>
        <li>Cloud Compute Infrastructure</li>
        <li>Bioinformatics Pipeline Development</li>
        <li>Full Stack Software Development</li>
        <li>Bioinformatics Research and Analysis</li>
        <li>AI and Machine Learning</li>
        <li>Cloud Cost Savings</li>
        <li>Agile Software Project Management</li>
        <li>Assistance in hiring processes</li>
        <li>Cloud IT and Cyber Security</li>
      </ul>
      </div>
    </div>
  );
};

export default ImageWithText;