import React, { useRef, useEffect, useState } from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageWithText from './components/ImageWithText';
import DnaAnimation from './components/DnaAnimation';
import MeetTheTeam from './components/MeetTheTeam';
import WaveHello from './components/WaveHelloStatic';
import './App.css';

function App() {
  const [showText, setShowText] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const recordClick = () => {
    setClicked(true);
  };

  return (
    <div style={{ fontFamily: 'Alata' }} onClick={recordClick}>
      <Header />
      <div style={{ marginTop: '60px' }} className="mobile-padding" >
        <div className="text-overlay">
          <div className="main-text">the complex, made simple</div>
          {window.innerWidth <= 768 ? (
            <div className={`click-text ${showText && !clicked ? 'visible' : ''}`}>
              (double click & drag me)
            </div>
          ) : (
            <div className={`click-text ${showText && !clicked ? 'visible' : ''}`}>
              (click & drag me)
            </div>
          )}
        </div>
        <DnaAnimation />
        <ImageWithText />
        <MeetTheTeam />
        <WaveHello />
        <Footer />
      </div>
    </div>
  );
}

export default App;