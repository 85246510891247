import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import dna_file from "../assets/DNA.obj";
import useFadeOnScroll from './UseFadeOnScroll';

const DnaAnimation = () => {
  const sceneRef = useRef('scene');
  const blockRef = useRef('block');
  const loader = new OBJLoader();
  const { addElementRef, removeElementRef } = useFadeOnScroll({ threshold: 0.5 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleOverlayDoubleClick = () => {
    sceneRef.current.removeChild(blockRef.current)
    sceneRef.current.addEventListener('dblclick', handleBaseDoubleClick)
  };

  const handleBaseDoubleClick = () => {
    sceneRef.current.removeEventListener('dblclick', handleBaseDoubleClick)
    sceneRef.current.appendChild(blockRef.current)
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    addElementRef(sceneRef);
    return () => removeElementRef(sceneRef);
  }, [addElementRef, removeElementRef]);

  useEffect(() => {
    // Three.js code
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 6;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight - (window.innerHeight * .1));
    renderer.setClearColor(0xffffff); // Set the background color to white
    sceneRef.current.appendChild(renderer.domElement);

    let dna;
    let directionalLight1 = null;
    let controls;

    console.log(blockRef.current)

    const onDocumentMouseClick = (event) => {
      event.preventDefault();
      if (!directionalLight1) {
        directionalLight1 = new THREE.DirectionalLight(0xffffff, 12);
        directionalLight1.position.set(5, 5, 5);
        scene.add(directionalLight1);
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 12);
        directionalLight2.position.set(-1, -1, -1);
        scene.add(directionalLight2);
      }
      if (dna) {
        dna.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.color.set(Math.random() * 0xffffff); // Set random colour
          }
        });
      }
    };

    renderer.domElement.addEventListener('click', onDocumentMouseClick, false);

    loader.load(
      dna_file,
      (object) => {
        dna = object;
        scene.add(object);

        // Create a material for the object
        const metallicTexture = new THREE.TextureLoader().load('path/to/metalness/texture.png');
        const material = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          metalness: 1.0, // Set the overall metalness value
          metalnessMap: metallicTexture, // Use the metalness texture
        });

        // Apply the material to the object
        dna.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = material;
          }
        });

        // Add orbit controls
        controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 0, 0); // Set the orbit control target to the center of the scene
        controls.enableZoom = false;
        controls.update();

        dna.position.set(0, -1.75, 0);
        dna.rotation.y = 1.5555;
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    // Update camera aspect ratio and renderer size on window resize
    const cameraResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height - (height * .1));
    };

    window.addEventListener('resize', cameraResize);

    // Render the scene
    function animate() {
      requestAnimationFrame(animate);
      if (dna) {
        dna.rotation.x += 0.001;
        dna.rotation.y += 0.001;
      }
      renderer.render(scene, camera);
    }
    animate();

    // Clean up on unmount
    return () => {
      window.removeEventListener('resize', cameraResize);
      sceneRef.current.removeChild(renderer.domElement);
    };
  }, [isMobile]);

  return <div ref={sceneRef} style={{position: 'relative', width: '100vw', height: 'calc(100vh - 80px)' }}>
    {isMobile && (
        <div ref={blockRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            pointerEvents: 'auto',
            zIndex: '99',
          }}
          onDoubleClick={handleOverlayDoubleClick}
        />
      )}
    </div>;
};

export default DnaAnimation;