import React, { useState, useEffect, useRef } from 'react';
import HenryPhoto from "../assets/henry.png";
import AlexPhoto from "../assets/alex2.png";
import './TeamSection.css';
import useFadeOnScroll from './UseFadeOnScroll';

const TeamSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const teamRefs = useRef([]);
  const sectionRef = useRef("sectionRef");

  const { addElementRef, removeElementRef } =
    useFadeOnScroll({ threshold: isMobile ? 0.2 : 0.2});

  useEffect(() => {
    addElementRef(sectionRef);
        return () => removeElementRef(sectionRef);
    }, [addElementRef, removeElementRef]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        const scrollPosition = window.scrollY;

        teamRefs.current.forEach((ref, index) => {
          if (ref) {
            const sectionTop = ref.offsetTop;
            const sectionHeight = ref.offsetHeight;

            if (
              scrollPosition >= sectionTop - window.innerHeight / 2 &&
                scrollPosition < sectionTop + sectionHeight - window.innerHeight / 2
            ) {
              setActiveIndex(index);
            }
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const teamMembers = [
    {
      name: 'Henry Farmery, PhD',
      photo: HenryPhoto,
      blurb: (
        <>
          Henry is a computational biologist with a plethora of
          experiences across biotechnology. The core of his expertise lies at
          the intersection between bioinformatics and the cloud.<br /><br />
          Prior to co-founding royston.io, Henry was Director of Informatics at
          Alchemab where he built the cloud bioinformatics capabilities from scratch.
          He went onto lead the software and IT functions at the company
          as part of the Senior Leadership Team.<br /><br />
          Henry holds a PhD and MPhil in Computational Biology from the University
          of Cambridge.
        </>
      ),
    },
    {
      name: 'Alex Farmery',
      photo: AlexPhoto,
      blurb: (<>Alex handles our operational activities. 
              Before she co-founded royston.io, she was working at the popular 
              outdoor bathing experience PAUS, based in Cambridgeshire. In her role as
              Assistant Director she was instrumental in building the 
              company from the ground up.<br /><br />
              The fact that Alex and Henry share a surname is no coincidence. 
              We're married!
              </>),
    },
    // Add more team members as needed
  ];

  return (
    <div className="team-section">
      <div className="team-title" ref={sectionRef}>Meet the Team</div>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            ref={(el) => (teamRefs.current[index] = el)}
            className={`team-member ${activeIndex === index || isMobile ? 'active' : ''}`}
          >
            <div className={`member-profile ${index % 2 === 0 ? 'left' : 'right'}`}>
              {index % 2 === 0 ? (
                <>
                  <div className="member-details">
                    {(isMobile || activeIndex === index) && <p>{member.blurb}</p>}
                  </div>
                  <div className="photo-container">
                    <img src={member.photo} alt={member.name} className="team-photo" />
                    <h3>{member.name}</h3>
                  </div>
                </>
              ) : (
                <>
                  <div className="photo-container">
                    <img src={member.photo} alt={member.name} className="team-photo" />
                    <h3>{member.name}</h3>
                  </div>
                  <div className="member-details">
                    {(isMobile || activeIndex === index) && <p>{member.blurb}</p>}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;