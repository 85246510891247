import React from 'react';
import Logo from "../assets/royston_logo.png";
import './Header.css';

function Header() {
  return (
    <div className="header-container">
      <header className="header">
        <img className="logo" src={Logo} alt="Logo" />
        <nav className="nav">
          <ul className="nav-list">
            <li className="nav-item">
              <a className="nav-link" href="#">scroll down</a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default Header;