import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import cloud_file from "../assets/Cloud.obj";

const CloudAnimation = () => {
  const parentRef = useRef("cloud_parent");
  const sceneRef = useRef("cloud_scene");
  const blockRef = useRef("cloud_block");
  const loader = new OBJLoader();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleOverlayDoubleClick = () => {
    console.log('Removed!')
    parentRef.current.removeChild(blockRef.current)
    sceneRef.current.addEventListener('dblclick', handleBaseDoubleClick)
  };

  const handleBaseDoubleClick = () => {
    console.log('Added')
    sceneRef.current.removeEventListener('dblclick', handleBaseDoubleClick)
    parentRef.current.appendChild(blockRef.current)
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75, sceneRef.current.clientWidth / sceneRef.current.clientHeight, 0.1, 1000);
    camera.position.z = 300;
    const renderer = new THREE.WebGLRenderer({ antialias: true });

    renderer.setSize(sceneRef.current.clientWidth, sceneRef.current.clientHeight);
    renderer.setClearColor(0xffffff); // Set the background color to white

    let cloud;

    const onDocumentMouseClick = (event) => {
      event.preventDefault();

      if (cloud) {
        cloud.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material.color.set(Math.random() * 0xffffff); // Set the color to red
          }
        });
      }
    };

    renderer.domElement.addEventListener('click', onDocumentMouseClick, false);

    loader.load(
      cloud_file,
      (object) => {
        cloud = object;
        scene.add(object);

        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 10);
        directionalLight1.position.set(5, 5, 5);
        scene.add(directionalLight1);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 10);
        directionalLight2.position.set(-1, -1, -1);
        scene.add(directionalLight2);

        // Create a material for the object
        const metallicTexture = new THREE.TextureLoader().load('path/to/metalness/texture.png');
        const material = new THREE.MeshStandardMaterial({
          color: 0x999999,
          metalness: 1.0, // Set the overall metalness value
          metalnessMap: metallicTexture, // Use the metalness texture
        });

        // Apply the material to the object
        cloud.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = material;
          }
        });

        // Add orbit controls
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.target.set(0, 0, 0); // Set the orbit control target to the center of the scene
        controls.enableZoom = false;
        controls.update();

        cloud.position.set(66, -50, 0);
        cloud.rotation.x = .2;
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    // Render the scene
    function animate() {
      requestAnimationFrame(animate);
      if (cloud) {
        cloud.rotation.y += 0.001;
      }
      renderer.render(scene, camera);
    }

    animate();

    // Resize the renderer when the window is resized
    const adjustCamera = () => {
      const width = sceneRef.current.clientWidth;
      const height = sceneRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', adjustCamera);

    if (!sceneRef.current.hasChildNodes()) {
      sceneRef.current.appendChild(renderer.domElement);
    }else{
      while (sceneRef.current.firstChild) {
        sceneRef.current.removeChild(sceneRef.current.firstChild);
      }
      sceneRef.current.appendChild(renderer.domElement);
    }

    // Clean up on unmount
    return () => {
      window.removeEventListener('resize', adjustCamera);
    };
  }, [isMobile]);

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%' , position: 'relative' }}>
      <div ref={sceneRef} style={{ width: '100%', height: '100%'}} />
      {isMobile && (
        <div
          ref={blockRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            pointerEvents: 'auto',
            zIndex: '1000',
          }}
          onDoubleClick={handleOverlayDoubleClick}
        />
      )}
    </div>
  );

};

export default CloudAnimation;