import { useEffect, useRef } from 'react';

const useFadeOnScroll = (options) => {
  const { threshold = 0.5 } = options || {};
  const elementRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      elementRefs.current.forEach((ref) => {
        const imageElement = ref.current;
        if (imageElement) {
          const imageTop = imageElement.getBoundingClientRect().top;
          const imageMid = imageTop + (imageElement.getBoundingClientRect().height / 2);
          const windowHeight = window.innerHeight;

          if (imageTop < windowHeight) {
            const opacity = 1 - (windowHeight - imageMid) / (windowHeight - (windowHeight * threshold));
            imageElement.style.opacity = 1 - Math.abs(opacity);
          } else {
            imageElement.style.opacity = 1;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  const addElementRef = (ref) => {
    if (ref && !elementRefs.current.includes(ref)) {
      elementRefs.current.push(ref);
    }
  };

  const removeElementRef = (ref) => {
    elementRefs.current = elementRefs.current.filter((r) => r !== ref);
  };

  return { addElementRef, removeElementRef };
};

export default useFadeOnScroll;