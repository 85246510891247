import React from 'react';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-section">
        <h4>Pages</h4>
        <ul className="footer-links">
          <li><a href="/credits">Credits</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="mailto:wave.hello@royston.io">Contact</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>About</h4>  
        <div className="address">
        This website was made with <span role="img" aria-label="UK Flag">❤️</span> in Cambridge <span role="img" aria-label="UK Flag">🇬🇧</span> by royston.io</div>
        <div>royston.io is the trading name of Royston Bioindustries Ltd</div>
      </div>
      <div className="footer-section connect-section">
        <h4>Connect</h4>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/henry-farmery/" className="social-link">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="https://www.x.com/jhrf" className="social-link">
            <i className="fa-brands fa-x-twitter"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;